﻿/*
 *  Document   : theme-white.scss
 *  Author     : RedStar Template
 *  Description: This scss file for white theme style classes
 */
@import '_theme-color-variables';

.theme-white {
  .navbar {
    background-color: transparent;
  }
  .navbar.active {
    background-color: $theme-white !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    .nav {
      > li {
        > a {
          color: #3a2c70;
        }
      }
    }
    .collapse-menu-icon .mat-icon {
      color: #3a2c70;
    }
    .nav-notification-icons .mat-icon {
      color: #3a2c70;
    }
  }

  .navbar-brand {
    color: $theme-white-navbar-brand;

    &:hover {
      color: $theme-white-navbar-brand_hover;
    }

    &:active {
      color: $theme-white-navbar-brand_active;
    }

    &:focus {
      color: $theme-white-navbar-brand_focus;
    }
  }

  .nav {
    > li {
      > a {
        &:hover {
          background-color: $theme-white-nav-anchor_hover;
          text-decoration: none;
        }

        &:focus {
          background-color: $theme-white-nav-anchor_focus;
          text-decoration: none;
        }
      }
    }

    .open {
      > a {
        background-color: $theme-white-nav-anchor-opened;

        &:hover {
          background-color: $theme-white-nav-anchor-opened_hover;
        }

        &:focus {
          background-color: $theme-white-nav-anchor-opened_focus;
        }
      }
    }
  }

  .bars {
    color: $theme-white-bar;
  }

  .sidebar {
    .legal {
      background-color: $theme-white-legal-bg;

      .copyright {
        a {
          color: $theme-white !important;
        }
      }
    }
  }

  .breadcrumb li a {
    color: $theme-white-active-color !important;
  }

  .card .header h2 strong {
    color: $theme-white-active-color !important;
  }

  .page-item.active .page-link {
    background-color: $theme-white-active-color;
    border-color: $theme-white-active-color;
    border-radius: 50%;
    margin: 5px;
    box-shadow: 0 4px 5px 0 #d4d8da, 0 1px 10px 0 #d4d8da,
      0 2px 4px -1px #d4d8da;
    padding: 0px 12px;
    min-width: 30px;
    line-height: 30px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .btn-primary {
    background-color: $theme-white-active-color !important;
    color: #fff !important;
    border-color: $theme-white-active-color !important;
    &:hover {
      background-color: $theme-white-active-color !important;
      color: #fff !important;
    }
    &:active {
      background-color: $theme-white-active-color !important;
      color: #fff !important;
    }
    &:focus {
      background-color: $theme-white-active-color !important;
      color: #fff !important;
    }
    &:disabled {
      background-color: $theme-white-active-color !important;
      color: #fff !important;
    }
  }
  .btn-outline-primary {
    background: 0 0 !important;
    color: $theme-white-bar !important;
    border: 1px solid $theme-white-active-color !important;
    &:hover {
      background: $theme-white-active-color !important;
      color: #fff !important;
      border: 1px solid $theme-white !important;
    }
  }
  .rounded-btn {
    width: 34px;
    height: 31px;
    padding: 5px;
    border-radius: 15px;
    text-align: center;
  }
  .rounded-btn-active {
    width: 50px;
    height: 22px;
    padding: 0px;
    border-radius: 15px;
    text-align: center;
  }
  .timelineLabelColor strong {
    color: $theme-white-active-color !important;
  }
  .top-sidebar {
    .horizontal-menu {
      li {
        &.active {
          > a {
            color: $theme-white-active-color;
          }
        }
        a {
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          &:hover {
            color: $theme-white-active-color;
          }
        }
      }
    }
  }
  .demo-choose-skin li.actived:after {
    color: #000;
  }

  .nav-tabs > li > a:before {
    border-bottom: 2px solid $theme-white-active-color;
  }
  .search-box input#search {
    color: gray;
    background: #edecec;
  }

  .settingSidebar ul.choose-theme li.active div::after {
    color: #000;
  }

  /* Custom label types */
  .label-type-gray {
    background: #e0e0e0;
    color: #424242;
  }

  /* Custom label types for shopping status */
  .lbl-shopping-status-yellow {
    background: #d7b41538;
    color: #aa8002;
  }

  .lbl-shopping-status-green {
    background: #12834f24;
    color: #028048;
  }

  .lbl-shopping-status-blue {
    background: #1351992e;
    color: #0b4287;
  }

  .lbl-shopping-status-red {
    background: #ff00181f;
    color: #ff0000e8;
  }

  .mat-form-field {
    padding: 0px 4px;
  }
}
/*Logo Header Background Color*/
.logo-white {
  .navbar-header {
    background-color: $theme-white;

    .logo-name {
      color: #6f6f6f;
      font-weight: 500;
    }
  }
}
/* Label colors */
.label {
  border-radius: 9px;
  min-width: 83px;
  padding: 1.5px 7px;
  text-align: center;

  &.label-default {
    background-color: transparent;
    // color: #333333f5;
  }

  &.label-off {
    background-color: #656565;
    // color: #333333f5;
  }

  &.label-on {
    background-color: #198754;
    // color: #198754;
  }

  &.label-blink {
    background-color: #ff9113;
    // color: #ff3d00;
  }
}
/* Status LED label colors */
.led-status {
  border-radius: 9px;
  min-width: 83px;
  padding: 1.5px 7px;
  text-align: center;

  .mat-icon {
    height: 33px;
    width: 33px;
  }

  &.default {
    color: #656565;
  }

  &.off {
    color: #656565;
    path {
      fill: #656565
    }
  }

  &.on {
    color: #198754;
    path {
      fill: #198754
    }
  }

  &.blink {
    color: #ff9113;
    path {
      fill: #ff9113
    }
  }
}

.no-results {
  margin-top: -50px;
  >p {
    padding: 0px;
  }
}

.mat-chip.mat-standard-chip::after {
  background: inherit;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-points {
  padding: 2px;
  min-width: 5rem;
  min-height: 1.5rem;
  justify-content: center;
  background-color: #afdaff40;
  color: #005dad;
  font-weight: 500;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-points-default {
  padding: 2px;
  min-width: 5rem;
  min-height: 1.5rem;
  justify-content: center;
  background-color: #b6c7d740;
  color: #6a6a6a;
  font-weight: 500;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-available-points {
  padding: 2px;
  min-width: 5rem;
  min-height: 1.5rem;
  justify-content: center;
  font-weight: 500;
  background-color: #12af3912;
  color: #07821f;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-total-installations {
  padding: 2px;
  min-width: 5rem;
  min-height: 1.5rem;
  justify-content: center;
  background-color: #4f178b14;
  color: #441477;
  font-weight: 500;
}
