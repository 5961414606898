﻿/*
 *  Document   : alert.scss
 *  Author     : RedStar Template
 *  Description: This scss file for alert style classes
 */

 @import '../theme/theme-color-variables';

.swal2-close {
  font-size: 2.5em !important;
}

.alert-primary {
  color: $theme-white;
  background-color: #c7e1fc;
  border-color: #b2d4f8;

  .swal2-input-label {
    font-size: 1.2rem;
  }
}

.alert-input {
  font-size: 1.2rem !important;
}

.alert-title {
  font-size: 1.6em !important;
}

// .alert-icon {
//   width: 4em !important;
//   height: 4em !important;
// }

.alert-confirm-btn {
  background-color:  lighten($theme-white,8%) !important;
  outline: none !important;
  border-style: hidden !important;
  border-color: transparent !important;
  box-shadow: none !important;
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border-style: hidden !important;
  }
  &:active {
    box-shadow: none !important;
    outline: none !important;
    border-style: hidden !important;
  }
}

.alert-cancel-btn {
  background-color: #727272 !important;
  outline: none !important;
  border-style: hidden !important;
  border-color: transparent !important;
  box-shadow: none !important;
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border-style: hidden !important;
  }
  &:active {
    box-shadow: none !important;
    outline: none !important;
    border-style: hidden !important;
  }
}