﻿/*
 *  Document   : _buttons.scss
 *  Author     : RedStar Template
 *  Description: This scss file for button style classes
 */

.default-button-row button,
.default-button-row a {
  margin-right: 8px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
.btn-space {
  margin-right: 10px !important;
}

.date-range {
  .calendar-btn {
    padding: 4px 4px;
    line-height: 30px;
    border-radius: 25px;
    min-width: 45px;
    margin: 0px 8px;
  }
}
