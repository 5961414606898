.drag-drop {
    width: 100%;
  }
  
  .default-container {
    width: 500px;
    max-width: 100%;
    margin: 0 10px 20px 10px;
    display: inline-block;
    vertical-align: top;
  }
    
  .default-list {
    border: solid 1px #ccc;
    min-height: 380px;
    max-height: 380px;
    background: white;
    border-radius: 4px;
    // overflow: auto;
    display: block;
  }
  
  .default-box {
    padding: 15px 10px;
    border-bottom: solid 1px #dfdfdf;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: #f5f5f5;
    font-size: 13px;
    // min-width: 350px;
  }
  
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .default-box:last-child {
    border: none;
  }
  
  .default-list.cdk-drop-list-dragging .default-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .default-custom-placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  