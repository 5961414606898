/*
 *  Document   : _noUISlider.scss
 *  Description: This scss file for noUISlider component style classes
 */
%extend_search {
  outline: none;
  opacity: 1;
  margin-left: -43px;
}

.search-box {
  overflow: hidden;
  width: 270px;
  vertical-align: middle;
  white-space: nowrap;
  input {
    &#search {
      width: 250px;
      height: 40px;
      background: rgb(255, 255, 255);
      border: none;
      font-size: 10pt;
      float: left;
      color: #fff;
      padding-left: 15px;
      margin-bottom: 0;
      margin-top: 10px;
      border-radius: 50px;
      &:-moz-placeholder {
        color: #65737e;
      }
      &:-ms-input-placeholder {
        color: #65737e;
      }
    }
    &#search::-webkit-input-placeholder {
      color: #65737e;
    }
    &#search::-moz-placeholder {
      color: #65737e;
    }
  }
  button.icon {
    border: none;
    background: transparent;
    height: 50px;
    width: 0px;
    color: #4f5b66;
    opacity: 0;
    font-size: 10pt;
    transition: all 0.55s ease;

    .fa {
      font-size: 18px;
    }
  }
  &:hover button.icon {
    @extend %extend_search;
    &:hover {
      background: transparent;
    }
  }
  &:active button.icon {
    @extend %extend_search;
  }
  &:focus button.icon {
    @extend %extend_search;
  }
}
